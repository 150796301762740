import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import Image from '../components/Image';

const LeClassement= props => (
  <Layout>
    <Helmet>
      <title>Classements - Palette Sartoise</title>
      <meta name="description" content="Classements des interclubs" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Classements des interclubs</h2>
        </header>

        <section id="content">
        <p>
          <Image alt="Classements - Palette Sartoise" filename="classements-interclubs.jpg" />
        </p>
        <p>
           <iframe src="https://www.vedrinamur.be/dynam/claclub.php?Indice=N188" width="100%" height="800" allowtransparency="true" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe>
        </p>
        </section>
      </div>
    </div>
  </Layout>
)

export default LeClassement
